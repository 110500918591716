import React from 'react'
import { graphql, useStaticQuery, PageProps } from 'gatsby'

// import Cursor from './cursor'

type Props = {
  children: any
  location: any
}

const Layout: React.FC<Props> = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query Layout {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  const rootPath = `${data.site.siteUrl}/`
  const isRootPath = location.pathname === rootPath

  return (
    <>
      <div data-is-root-path={isRootPath}>
        <main>{children}</main>
      </div>
      {/* <Cursor></Cursor> */}
    </>
  )
}

export default Layout
