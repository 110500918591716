/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useStaticQuery, graphql } from 'gatsby'

import info from '../../site/settings/info.json'

type Props = {
  description?: string
  lang?: string
  meta?: {
    property: string
    content: string
  }
  title?: string
  image?: any
  pathname?: string
}

const SEO = (props: Props) => {
  const { description, lang, meta, title, image: metaImage, pathname } = props
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
  )

  const metaDescription = description || info.description
  const defaultTitle = info.title

  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : 'monica'

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      title={title}
      titleTemplate={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@ragoli',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat([
          {
            property: 'og:image',
            content: image,
          },
          {
            name: 'twitter:image',
            content: image,
          },
          {
            name: 'og:image:secure_url',
            content: image,
          },
          {
            property: 'og:image:width',
            content: metaImage?.width || 800,
          },
          {
            property: 'og:image:height',
            content: metaImage?.height || 800,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
        ])
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: {
    src: `https://lasrepublicasdelosalvaje.hellojungla.com${'monica'}`,
    height: 800,
    width: 800,
  },
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}

export default SEO
